import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
<path d="M5005 9183 c-122 -75 -406 -238 -590 -338 -82 -45 -226 -124 -320
-177 -93 -52 -228 -125 -300 -163 -71 -38 -202 -110 -290 -160 -88 -50 -221
-125 -295 -165 -74 -41 -223 -126 -330 -191 -107 -64 -224 -131 -260 -149 -36
-18 -107 -62 -158 -98 l-93 -64 3 -499 3 -499 -117 0 -117 0 5 33 c21 121 92
375 139 495 30 79 55 156 55 172 0 27 -42 90 -60 90 -11 0 -113 -264 -155
-405 -20 -66 -52 -187 -71 -269 -18 -81 -38 -164 -44 -185 -5 -20 -10 -39 -10
-43 0 -5 295 -8 656 -8 614 0 658 -1 670 -17 7 -10 14 -27 14 -39 0 -19 -7
-22 -67 -27 -38 -4 -289 -7 -559 -7 -270 0 -546 -3 -612 -7 l-122 -6 0 -54 0
-53 543 1 c298 1 614 4 702 7 l160 7 45 -42 c25 -23 42 -45 38 -49 -4 -4 -340
-7 -748 -8 l-740 -1 0 -64 0 -64 68 6 c37 4 301 9 587 12 389 5 536 10 585 20
36 8 99 17 140 20 41 4 91 12 110 19 33 11 38 9 95 -30 33 -23 89 -56 124 -74
77 -38 301 -122 389 -145 138 -35 230 -75 283 -122 187 -169 100 -386 -161
-401 -98 -5 -190 17 -292 70 -102 53 -174 119 -244 224 -31 46 -59 84 -62 84
-7 0 -68 -46 -253 -187 -97 -74 -209 -178 -209 -195 0 -22 79 -159 132 -231
66 -89 144 -158 289 -256 66 -45 113 -81 104 -81 -21 0 -19 -10 8 -41 53 -63
158 -128 413 -260 75 -38 156 -83 180 -99 39 -28 50 -30 128 -30 l85 0 20 -45
c17 -38 29 -50 73 -70 29 -14 64 -25 78 -25 38 0 100 40 137 88 28 37 33 52
33 98 0 65 -16 100 -65 142 -42 37 -71 46 -130 40 -52 -6 -128 -58 -145 -99
-12 -28 -15 -29 -75 -29 -48 0 -68 5 -89 21 -42 33 -380 216 -481 260 -101 44
-82 45 80 4 387 -98 824 31 1026 303 27 37 73 115 102 174 28 59 55 108 58
108 14 0 19 -87 19 -327 0 -144 4 -254 9 -258 5 -3 71 -7 146 -10 76 -2 140
-6 143 -9 7 -7 4 -284 -4 -308 -5 -17 -8 -16 -26 9 -12 15 -38 46 -59 68 -21
22 -45 57 -54 78 -20 49 -46 58 -152 55 l-84 -3 -37 42 c-40 45 -81 69 -127
74 -29 4 -30 3 -33 -42 -3 -42 0 -49 27 -68 16 -12 37 -21 48 -21 10 0 33 -9
52 -21 19 -11 65 -31 102 -44 79 -26 186 -108 249 -190 23 -29 57 -66 76 -80
l34 -26 0 -409 0 -410 -42 -27 c-24 -15 -99 -60 -167 -100 l-124 -73 -81 46
c-45 24 -139 74 -211 110 -71 36 -134 69 -140 74 -5 4 -105 58 -222 118 -116
61 -262 140 -325 175 -62 36 -187 106 -278 157 -286 161 -531 301 -640 365
-58 34 -177 105 -265 157 -88 52 -217 125 -286 163 l-126 69 1 548 1 548 -55
0 c-30 0 -65 3 -77 6 l-21 6 4 -581 3 -581 36 -32 c72 -63 123 -97 205 -137
106 -53 118 -62 93 -72 -10 -4 -31 -8 -46 -8 -16 -1 -27 -5 -25 -10 11 -29
267 -317 301 -339 39 -25 42 -30 39 -67 -5 -62 14 -113 51 -135 33 -20 77 -25
111 -11 15 6 28 -2 57 -34 21 -23 64 -61 96 -86 31 -24 82 -68 113 -97 44 -40
68 -54 103 -61 25 -4 75 -27 112 -50 112 -71 280 -143 500 -214 236 -77 513
-121 721 -115 99 3 102 2 174 -34 l73 -38 46 30 c106 69 120 73 244 60 62 -7
150 -9 198 -6 102 8 326 60 350 82 15 13 21 13 58 -7 61 -32 105 -22 156 34
33 37 54 49 123 72 99 32 293 123 340 158 32 24 36 24 67 11 46 -19 69 -18
113 5 60 31 84 64 90 121 4 46 8 52 40 67 20 10 55 36 79 59 41 39 46 41 92
36 79 -7 131 42 131 126 0 36 8 47 108 148 119 120 235 268 230 293 -3 13 -14
15 -58 13 -62 -4 -80 -17 -152 -110 -24 -30 -78 -95 -121 -143 -77 -87 -80
-89 -124 -89 -60 0 -97 -36 -107 -103 -7 -44 -12 -52 -83 -107 -42 -33 -81
-60 -87 -60 -6 1 -22 7 -36 15 -14 8 -42 14 -64 15 -32 0 -45 -7 -77 -39 -34
-34 -39 -45 -39 -84 0 -39 -4 -49 -30 -67 -38 -27 -274 -139 -358 -170 -61
-22 -65 -22 -98 -6 -44 20 -113 21 -141 1 -13 -9 -28 -34 -34 -55 -7 -22 -14
-40 -16 -40 -2 0 -54 -11 -115 -25 -61 -14 -163 -30 -227 -35 -64 -6 -127 -13
-139 -16 -37 -8 -17 13 44 47 30 17 59 40 65 51 6 12 8 48 5 91 -4 40 -9 251
-12 470 l-6 397 -21 0 c-20 1 -21 2 -4 14 15 12 17 34 17 210 l0 196 58 0 58
0 0 -679 -1 -679 46 27 c95 55 88 27 78 275 -5 120 -8 408 -7 640 l3 421 25
-3 c14 -1 34 -3 46 -3 19 1 22 -7 28 -61 3 -33 6 -310 6 -614 l0 -553 34 23
c19 12 48 29 65 36 l32 13 -5 106 c-11 211 -14 611 -8 836 l7 230 40 0 40 0 7
-65 c4 -36 7 -270 7 -520 0 -250 0 -474 0 -497 l1 -42 71 41 c54 31 69 44 63
57 -4 9 -8 277 -10 596 l-2 580 -69 -4 c-66 -3 -110 -22 -97 -42 3 -5 -4 -9
-14 -9 -11 0 -22 9 -25 19 -7 29 -55 33 -414 29 l-322 -3 -3 1110 c-2 611 -1
1184 2 1275 l5 165 175 3 c96 1 310 -1 475 -6 l300 -9 108 -42 c130 -51 265
-138 330 -212 57 -64 120 -188 143 -279 24 -94 15 -282 -16 -365 -41 -108 -95
-177 -194 -252 -34 -26 -65 -49 -69 -53 -4 -4 28 -30 72 -59 195 -126 303
-338 288 -565 -20 -317 -206 -556 -507 -655 l-65 -21 0 -574 c0 -316 3 -577 7
-581 3 -3 32 6 64 22 l57 28 1 484 c1 473 1 484 21 495 11 6 34 21 51 33 l31
22 7 -117 c7 -121 -9 -800 -20 -839 -4 -13 -2 -23 3 -23 6 0 83 45 173 101 90
55 242 143 337 195 96 52 232 130 303 174 71 44 166 98 211 120 44 23 119 66
166 97 78 51 84 58 78 82 -4 14 -6 577 -6 1251 1 674 -1 1337 -5 1473 l-7 248
-86 45 c-47 26 -115 63 -151 84 -193 110 -658 382 -890 520 -274 162 -482 282
-655 378 -55 30 -154 87 -220 127 -66 40 -160 93 -210 118 -49 25 -142 75
-205 112 -63 37 -152 90 -198 116 -46 27 -84 49 -85 48 -1 0 -42 -25 -92 -56z
m185 -176 c52 -29 161 -92 241 -139 80 -47 179 -101 221 -120 41 -19 110 -60
154 -90 43 -30 122 -78 174 -106 52 -28 172 -95 265 -150 212 -123 274 -159
450 -258 77 -43 217 -123 310 -179 94 -56 204 -119 245 -141 83 -45 218 -124
326 -193 l71 -45 7 -66 c3 -36 6 -671 6 -1411 l0 -1345 -162 -91 c-213 -118
-266 -149 -557 -320 -134 -79 -246 -142 -247 -140 -2 2 -6 29 -10 60 -6 50 -4
57 12 63 11 4 37 19 59 34 22 14 141 82 265 150 444 243 481 267 518 335 16
28 17 133 13 1320 -3 1103 -5 1293 -18 1313 -25 40 -130 115 -261 185 -186
101 -408 225 -482 271 -36 22 -128 74 -205 116 -77 43 -205 114 -285 160 -80
45 -220 121 -311 167 -176 89 -209 112 -209 144 0 32 -25 72 -73 116 -52 48
-103 65 -168 54 -104 -17 -164 -88 -164 -197 0 -119 85 -204 205 -204 45 0 65
6 96 26 21 15 45 35 52 45 20 29 -22 48 382 -174 171 -94 299 -166 650 -367
113 -65 282 -159 375 -210 94 -51 197 -114 230 -139 57 -45 60 -50 67 -102 3
-30 7 -448 7 -929 1 -515 5 -891 11 -915 6 -24 7 -157 3 -330 l-6 -290 -37
-35 c-33 -31 -123 -86 -475 -285 -60 -34 -140 -80 -176 -103 l-67 -41 -7 45
c-8 57 -1 76 34 94 16 8 51 28 77 43 27 16 120 67 206 115 186 103 235 137
274 188 29 37 29 39 29 171 l0 133 37 25 c86 59 114 174 62 262 -14 24 -40 53
-57 64 l-32 19 0 855 0 854 -50 52 c-61 63 -116 99 -310 204 -82 45 -190 106
-240 135 -49 28 -171 94 -270 145 -320 165 -325 168 -332 193 -15 63 -128 54
-182 -14 -12 -15 -21 -30 -21 -34 0 -9 139 -78 170 -84 26 -6 414 -211 620
-328 63 -36 177 -98 252 -138 106 -56 149 -85 191 -129 l54 -56 7 -84 c4 -46
6 -414 6 -818 -2 -813 4 -739 -63 -785 -39 -27 -67 -95 -67 -164 0 -74 37
-141 94 -168 l36 -17 0 -102 c-1 -108 -8 -132 -50 -164 -28 -22 -156 -94 -305
-173 -55 -29 -113 -62 -128 -73 -16 -10 -31 -16 -35 -12 -4 4 -8 71 -10 150
-3 135 -2 144 16 149 27 9 195 184 232 243 38 59 105 264 121 370 31 202 -41
454 -178 623 l-61 75 28 40 c45 66 107 195 124 260 23 87 31 235 17 339 -31
227 -155 414 -368 554 -209 137 -436 188 -779 175 -150 -6 -162 -5 -180 14
-28 28 -87 30 -112 3 -19 -20 -27 -21 -215 -18 -189 3 -196 2 -201 -17 -3 -11
-6 -392 -6 -846 0 -701 -2 -828 -14 -845 -14 -18 -16 -16 -35 31 -122 292
-286 427 -667 549 -343 110 -395 134 -435 200 -36 59 -20 132 47 206 46 51 91
74 172 90 125 25 225 -5 345 -102 34 -28 77 -57 95 -66 31 -15 35 -15 67 2 53
29 176 147 258 249 41 50 93 109 116 130 22 21 41 45 41 55 0 27 -129 143
-216 194 -253 148 -393 190 -637 189 -228 -1 -352 -35 -552 -152 -161 -94
-280 -219 -355 -373 -60 -122 -74 -188 -75 -351 0 -77 -3 -142 -7 -145 -7 -5
-740 -2 -743 3 0 1 1 209 3 463 l4 461 109 64 c151 88 271 158 354 206 39 22
115 67 170 100 55 33 177 102 270 153 94 51 183 102 199 113 32 22 295 166
486 265 69 35 170 90 225 122 55 31 170 94 255 140 85 45 193 107 240 138 89
57 241 149 250 151 3 0 48 -23 100 -52z m445 -452 c51 -50 18 -121 -54 -119
-53 2 -81 26 -81 70 0 69 85 100 135 49z m-1200 -1030 c44 -8 104 -22 134 -31
66 -19 331 -150 331 -164 0 -13 -106 -151 -167 -220 l-50 -54 -51 37 c-72 53
-186 104 -267 118 -228 39 -449 -59 -537 -239 -30 -60 -33 -76 -32 -152 0
-102 23 -157 92 -226 57 -56 139 -96 264 -128 53 -14 127 -37 165 -52 37 -14
95 -34 128 -44 84 -25 149 -56 229 -107 108 -69 167 -135 219 -244 63 -131 81
-210 81 -344 -1 -130 -18 -202 -76 -324 -32 -68 -57 -101 -122 -165 -154 -153
-350 -236 -561 -236 -259 0 -524 89 -695 233 -76 63 -200 207 -200 230 0 11
109 98 206 164 l32 22 61 -69 c78 -87 208 -174 321 -214 80 -29 94 -31 230
-31 l146 0 76 38 c225 110 294 364 156 571 -33 51 -128 124 -202 155 -26 12
-144 53 -263 92 -276 91 -376 141 -464 228 -74 75 -138 177 -165 266 -28 91
-27 296 3 390 37 116 151 268 263 350 105 77 276 143 435 169 48 7 193 -2 280
-19z m2855 -2035 c51 -27 18 -129 -43 -130 -24 0 -67 44 -67 70 0 27 43 70 70
70 12 0 30 -4 40 -10z m-2592 -957 c40 -44 5 -113 -58 -113 -40 0 -62 30 -58
81 2 35 8 46 28 56 32 16 59 8 88 -24z m-1845 -154 c23 -12 116 -65 207 -119
315 -185 566 -328 690 -395 69 -37 130 -71 135 -75 6 -4 89 -49 185 -100 452
-239 630 -338 630 -352 0 -19 -244 19 -400 62 -317 87 -561 192 -725 310 -44
31 -100 68 -125 80 -59 30 -123 73 -186 126 -44 37 -53 50 -58 88 -14 94 -81
145 -146 113 -30 -15 -31 -15 -40 7 -10 27 -123 171 -183 234 -47 48 -44 51
16 21z"/>
<path d="M4999 8900 c-46 -24 -88 -74 -96 -111 -5 -27 -9 -29 -54 -29 -36 0
-68 -10 -126 -39 -43 -21 -121 -60 -173 -86 -101 -50 -258 -135 -455 -245 -66
-37 -187 -104 -270 -148 -82 -44 -220 -119 -305 -167 -85 -48 -240 -134 -345
-192 -419 -233 -550 -316 -560 -356 -3 -12 -4 -193 -3 -402 l3 -380 35 -3 c66
-5 63 -20 70 377 3 215 9 364 15 369 28 26 179 120 273 171 59 32 197 109 307
171 822 464 1374 760 1480 793 97 30 113 30 129 -1 31 -59 101 -102 168 -102
59 0 116 35 156 95 32 47 34 54 30 121 -3 58 -8 77 -30 106 -55 73 -171 99
-249 58z m154 -121 c26 -32 22 -83 -9 -108 -15 -12 -38 -21 -51 -21 -31 0 -83
51 -83 82 0 25 26 62 50 71 26 10 74 -2 93 -24z"/>
<path d="M4567 8415 c-43 -18 -84 -38 -90 -43 -7 -5 -70 -40 -142 -77 -250
-130 -490 -257 -500 -265 -15 -11 -342 -192 -570 -315 -232 -125 -400 -224
-433 -255 -22 -21 -23 -28 -22 -158 1 -103 -2 -148 -14 -181 -21 -58 -20 -89
5 -156 20 -53 20 -57 3 -125 -9 -39 -14 -73 -10 -77 7 -8 77 -6 85 3 3 3 6 28
6 55 0 46 1 49 26 49 35 0 47 -16 51 -70 l3 -45 53 -3 52 -3 0 55 c0 47 5 61
29 88 81 94 90 171 27 262 -39 57 -45 95 -20 121 9 9 51 33 93 55 79 39 238
127 393 218 48 28 167 92 265 143 98 51 205 108 238 127 33 19 141 79 240 132
99 53 194 105 211 115 l31 19 79 -60 c66 -50 81 -66 96 -108 32 -90 89 -131
174 -124 108 9 165 79 150 182 -18 120 -164 178 -243 97 l-28 -28 -29 29 c-16
16 -48 38 -71 49 -23 11 -56 35 -74 53 -28 28 -37 33 -60 26 -38 -11 -137 -62
-291 -150 -74 -43 -218 -121 -320 -175 -206 -109 -638 -345 -800 -437 -152
-87 -170 -102 -170 -143 0 -37 -24 -68 -49 -63 -20 4 -25 36 -16 105 6 52 9
56 62 90 68 43 741 413 1083 596 36 19 146 78 245 132 99 54 204 111 234 126
49 27 61 29 165 29 61 0 128 -5 150 -11 41 -11 99 -63 111 -98 8 -26 73 -61
115 -61 38 0 86 24 100 49 17 32 12 142 -8 181 -37 74 -120 99 -193 58 -30
-17 -41 -19 -53 -9 -15 13 -159 31 -241 30 -34 0 -75 -11 -128 -34z m543 -80
c40 -21 41 -65 4 -94 -51 -40 -84 -19 -84 53 0 23 6 39 18 45 23 14 28 13 62
-4z m-152 -341 c15 -10 22 -25 22 -48 0 -43 -19 -58 -67 -54 -36 3 -38 5 -41
40 -2 27 3 43 17 57 25 25 39 26 69 5z m-1919 -915 c26 -46 -8 -99 -65 -99
-22 0 -64 46 -64 70 0 59 99 82 129 29z"/>
<path d="M5515 8213 c-121 -63 -156 -180 -92 -305 28 -55 39 -67 86 -90 69
-35 94 -35 168 2 120 60 171 174 124 275 -22 47 -84 109 -124 124 -41 16 -127
13 -162 -6z m149 -124 c37 -43 38 -79 3 -116 -43 -48 -138 -18 -152 48 -8 34
4 52 50 81 43 26 69 22 99 -13z"/>
<path d="M4515 7895 c-48 -47 -25 -125 40 -141 20 -5 33 0 55 21 51 48 51 104
0 130 -41 21 -67 19 -95 -10z"/>
<path d="M4220 6977 c-58 -30 -80 -74 -80 -156 0 -62 1 -65 43 -106 31 -31 56
-45 94 -55 50 -11 55 -11 98 15 25 14 57 44 71 65 23 34 26 48 22 100 -10 133
-130 200 -248 137z m134 -109 c32 -46 6 -100 -49 -100 -54 0 -86 69 -49 106
24 24 80 20 98 -6z"/>
<path d="M4753 6816 c-45 -21 -68 -70 -59 -130 6 -40 12 -48 40 -60 70 -29 95
-30 136 -2 49 32 70 88 49 131 -30 60 -106 88 -166 61z"/>
<path d="M6996 6489 c-39 -31 -56 -71 -56 -133 0 -62 27 -104 80 -126 80 -33
170 41 170 141 0 104 -119 177 -194 118z m109 -90 c20 -37 7 -59 -36 -59 -43
0 -63 45 -33 74 25 25 52 19 69 -15z"/>
<path d="M6960 5149 c-31 -12 -59 -47 -60 -74 0 -33 35 -81 74 -99 34 -16 38
-16 65 0 49 29 62 74 35 125 -12 24 -69 62 -86 58 -2 0 -14 -5 -28 -10z"/>
<path d="M5380 9161 c0 -12 98 -77 152 -100 25 -10 69 -24 99 -30 75 -15 266
-51 272 -51 3 0 19 -24 35 -53 49 -88 128 -110 202 -57 20 15 37 19 52 14 35
-10 364 -175 451 -225 146 -84 241 -153 270 -197 20 -30 47 -52 90 -73 120
-61 207 -140 330 -300 77 -101 109 -130 188 -170 69 -34 69 -34 69 -11 0 67
-164 280 -356 463 -59 56 -83 86 -92 117 -17 55 -83 97 -141 89 -34 -4 -44 0
-95 44 -109 93 -396 251 -615 339 -86 35 -95 41 -111 78 -25 54 -66 76 -137
70 -38 -3 -60 -11 -78 -28 -17 -16 -28 -20 -37 -13 -19 15 -271 81 -353 93
-88 12 -195 13 -195 1z"/>
<path d="M4545 9149 c-176 -23 -548 -140 -601 -190 -21 -20 -26 -21 -37 -7
-44 55 -137 65 -200 22 -41 -27 -57 -57 -57 -105 0 -22 -6 -32 -25 -39 -85
-32 -332 -194 -417 -272 -37 -34 -45 -37 -72 -30 -76 21 -136 -28 -136 -109 0
-27 -4 -49 -8 -49 -15 0 -120 -96 -192 -175 -181 -198 -202 -235 -135 -235 66
0 140 55 221 165 16 22 59 72 97 112 65 68 70 71 112 71 26 -1 55 6 70 16 27
18 55 71 55 104 0 19 146 131 245 188 28 15 87 52 132 81 64 41 81 57 76 70
-4 10 9 4 36 -18 36 -28 51 -34 91 -34 40 0 54 5 80 30 17 16 37 50 44 74 13
45 14 46 92 74 152 56 376 117 482 131 103 14 187 37 248 69 32 17 74 51 74
61 0 9 -192 5 -275 -5z"/>
<path d="M7873 7448 c-5 -7 -16 -34 -22 -59 -12 -43 -11 -51 14 -110 88 -210
143 -401 170 -585 l18 -121 -27 -56 c-32 -64 -30 -94 10 -152 21 -32 24 -47
24 -129 0 -295 -85 -750 -189 -1015 -53 -134 -61 -181 -40 -253 5 -21 14 -38
19 -38 14 0 91 157 134 272 38 102 114 393 140 538 24 128 46 366 46 486 0 90
3 110 20 131 35 45 36 85 1 159 -18 39 -31 82 -31 106 0 56 -46 273 -86 405
-28 94 -133 361 -162 412 -12 21 -27 24 -39 9z"/>
<path d="M5762 7212 l-143 -3 5 -385 c2 -227 8 -390 14 -396 5 -5 117 -8 283
-6 l275 3 66 33 c86 42 143 98 181 180 29 61 32 76 32 162 0 82 -4 103 -27
153 -52 110 -186 220 -299 246 -55 12 -201 17 -387 13z m379 -188 c50 -16 118
-75 146 -127 25 -46 25 -181 0 -222 -46 -76 -116 -95 -348 -95 l-159 0 0 230
0 229 73 4 c109 6 236 -2 288 -19z"/>
<path d="M5980 6989 c-86 -35 -132 -147 -96 -232 18 -43 78 -95 119 -102 73
-14 152 27 187 96 33 63 22 135 -29 193 -36 41 -133 65 -181 45z m104 -115
c19 -18 21 -75 4 -92 -16 -16 -73 -15 -86 1 -36 45 -14 107 38 107 15 0 36 -7
44 -16z"/>
<path d="M3262 6149 c-24 -12 -53 -37 -66 -56 l-23 -34 -551 1 c-303 0 -569
-3 -591 -6 -46 -7 -57 -26 -47 -80 l7 -33 411 3 c226 1 491 1 590 -2 l179 -5
30 -43 c39 -57 79 -78 151 -78 49 -1 62 4 95 30 115 93 113 201 -5 291 -53 40
-114 44 -180 12z m132 -110 c57 -45 10 -135 -57 -110 -38 15 -58 48 -46 79 20
52 60 65 103 31z"/>
<path d="M5672 6103 l-52 -4 4 -372 c2 -205 5 -389 5 -409 l1 -38 263 0 c284
0 317 5 421 57 72 37 161 130 197 206 36 78 45 142 30 221 -32 175 -158 304
-325 335 -55 10 -417 12 -544 4z m551 -176 c52 -24 114 -83 139 -131 44 -86
16 -214 -61 -275 -88 -70 -110 -76 -299 -79 -96 -2 -185 -1 -198 2 l-24 6 0
251 0 251 203 -4 c163 -3 209 -8 240 -21z"/>
<path d="M6012 5889 c-18 -5 -50 -27 -71 -48 -122 -122 -38 -321 136 -321 88
0 156 66 168 163 7 58 -2 92 -41 142 -42 55 -126 84 -192 64z m120 -126 c10
-9 18 -26 18 -39 0 -31 -47 -84 -75 -84 -35 0 -75 37 -75 70 0 56 89 92 132
53z"/>
<path d="M3774 5920 c-46 -19 -77 -84 -56 -117 31 -49 175 -39 188 14 16 63
-69 129 -132 103z"/>
<path d="M4045 5861 c-65 -40 -80 -66 -83 -149 -4 -73 -2 -79 27 -119 25 -34
41 -45 87 -59 62 -18 103 -14 152 16 111 68 100 254 -19 315 -40 21 -127 19
-164 -4z m139 -107 c20 -20 20 -63 0 -92 -18 -27 -69 -30 -96 -5 -22 20 -23
70 -1 95 20 22 76 24 97 2z"/>
<path d="M2598 5328 c-2 -287 -2 -528 0 -533 7 -29 436 -282 1067 -630 88 -48
219 -120 290 -160 72 -40 159 -89 195 -108 184 -102 447 -223 459 -211 27 26
-210 189 -519 354 -58 31 -130 71 -160 89 -90 54 -373 212 -400 224 -14 6 -45
25 -70 41 -43 29 -185 111 -420 241 -146 81 -296 180 -315 208 -13 20 -14 88
-12 510 l2 487 -42 0 c-23 0 -48 3 -57 6 -14 5 -16 -49 -18 -518z"/>
<path d="M2973 5482 l2 -362 -26 0 c-54 0 -54 4 -51 377 l3 343 -56 0 -55 0 0
-368 c0 -293 3 -379 14 -417 16 -50 13 -61 -26 -117 -10 -14 -8 -22 12 -44 44
-47 126 -96 625 -372 55 -30 116 -65 136 -77 19 -13 63 -37 97 -54 34 -17 86
-46 115 -65 28 -18 81 -48 117 -66 36 -18 94 -52 130 -75 36 -23 101 -61 146
-83 44 -22 123 -69 175 -105 52 -35 130 -83 175 -106 60 -31 100 -61 154 -118
40 -41 88 -81 106 -89 26 -11 36 -24 49 -64 43 -130 155 -176 276 -112 144 75
146 293 2 363 -34 17 -57 20 -103 17 -57 -4 -81 -16 -162 -78 l-26 -20 -84 70
c-107 89 -341 248 -438 296 -41 20 -140 74 -220 119 -80 45 -181 101 -225 125
-44 24 -186 105 -315 180 -129 76 -318 181 -420 234 -222 117 -252 138 -248
174 5 43 37 38 160 -26 384 -200 406 -212 828 -462 412 -244 582 -337 708
-389 71 -29 76 -30 180 -23 105 7 107 7 116 35 8 21 6 31 -7 44 -14 15 -26 15
-98 6 l-82 -11 -126 68 c-69 37 -200 111 -290 164 -91 53 -168 96 -173 96 -12
0 -307 163 -383 212 -38 25 -117 71 -175 103 -113 62 -264 150 -345 199 -27
17 -70 49 -95 72 -25 23 -52 44 -60 47 -9 3 -5 6 11 6 14 1 35 11 46 24 19 20
21 38 26 208 3 102 2 254 -1 338 l-5 152 -58 -3 -58 -3 2 -363z m2086 -1738
c20 -16 31 -35 31 -51 0 -33 -60 -93 -93 -93 -59 0 -89 100 -45 147 28 30 69
29 107 -3z"/>
<path d="M2046 5803 c55 -319 229 -864 281 -880 7 -3 16 22 24 66 l12 70 -66
208 c-78 243 -102 339 -118 474 l-12 99 -64 0 -64 0 7 -37z"/>
<path d="M5165 4231 c-11 -5 -33 -10 -50 -10 -58 -2 -62 -13 -30 -82 16 -34
41 -70 54 -80 41 -33 106 -22 143 25 21 25 24 104 5 130 -15 21 -91 31 -122
17z"/>
<path d="M3606 2880 c-35 -11 -73 -45 -93 -84 -21 -41 -13 -158 14 -194 61
-83 223 -79 268 7 l15 31 1233 2 c677 2 1237 2 1242 0 6 -2 19 -17 30 -34 35
-55 68 -72 135 -71 74 2 112 22 147 75 23 33 28 53 28 102 0 52 -4 67 -28 98
-41 53 -106 82 -165 74 -62 -8 -120 -48 -141 -97 l-16 -39 -1225 0 -1225 0
-28 45 c-45 70 -123 105 -191 85z m2874 -115 c37 -19 48 -57 27 -89 -18 -27
-63 -39 -90 -24 -41 22 -44 102 -3 118 30 12 33 12 66 -5z m-2788 -12 c26 -23
24 -78 -4 -97 -30 -21 -44 -20 -68 4 -55 55 14 146 72 93z"/>
<path d="M2594 2660 c-28 -11 -82 -75 -90 -106 -16 -62 25 -147 86 -179 94
-49 210 29 210 140 0 109 -108 185 -206 145z"/>
<path d="M7514 2656 c-110 -48 -108 -223 2 -277 174 -84 305 182 134 272 -41
22 -92 24 -136 5z"/>
<path d="M6720 2559 c-14 -6 -41 -25 -60 -44 l-35 -34 -1635 -3 c-1841 -3
-1695 3 -1695 -78 l0 -45 1681 4 1681 4 37 -41 c46 -50 85 -60 144 -37 62 25
82 56 82 125 0 43 -6 65 -22 90 -41 59 -115 84 -178 59z"/>
<path d="M3837 1888 c-15 -35 -42 -106 -61 -158 -18 -52 -52 -144 -75 -203
-22 -60 -41 -110 -41 -112 0 -3 25 -4 55 -3 53 1 55 2 55 29 0 57 18 77 74 84
28 4 78 5 111 3 l60 -3 18 -58 18 -57 59 1 c67 2 66 -8 11 112 -16 38 -57 132
-91 210 -72 170 -93 207 -119 207 -11 0 -26 2 -34 5 -9 4 -22 -13 -40 -57z
m95 -172 c42 -85 36 -101 -38 -93 -31 3 -58 7 -60 9 -5 6 50 148 59 148 4 0
21 -29 39 -64z"/>
<path d="M5840 1933 c0 -7 20 -59 44 -116 23 -56 51 -127 60 -157 10 -30 36
-99 58 -152 39 -96 41 -98 73 -98 65 0 70 5 135 165 21 50 60 142 87 205 74
168 73 156 12 164 -28 4 -52 6 -53 4 -10 -14 -118 -278 -136 -333 l-23 -70
-19 45 c-11 25 -42 110 -70 190 -28 80 -53 147 -55 150 -2 2 -28 7 -58 10 -42
5 -55 3 -55 -7z"/>
<path d="M6860 1935 c-179 -57 -240 -295 -115 -443 34 -40 91 -70 148 -78 57
-8 148 18 189 54 27 25 29 31 19 50 -6 12 -21 27 -32 33 -17 9 -25 7 -45 -12
-17 -16 -40 -24 -79 -27 -47 -4 -61 -1 -94 21 -47 31 -74 85 -75 153 0 45 5
59 30 91 42 53 88 75 143 70 23 -3 55 -14 70 -25 l27 -19 28 22 c38 30 31 53
-27 89 -56 35 -122 42 -187 21z"/>
<path d="M7800 1941 c-90 -20 -150 -76 -150 -137 0 -76 44 -125 135 -154 22
-7 57 -21 78 -32 30 -15 37 -24 37 -48 0 -58 -69 -76 -169 -43 -60 19 -72 13
-74 -38 -2 -30 2 -34 43 -50 59 -23 170 -24 217 -3 66 32 107 118 89 189 -8
32 -77 77 -161 105 -70 23 -100 49 -91 78 13 41 90 54 165 27 l41 -14 20 38
c10 21 16 42 12 46 -28 28 -135 48 -192 36z"/>
<path d="M2265 1804 c-4 -75 -4 -194 0 -266 l7 -131 127 6 c144 5 179 17 246
78 135 124 90 349 -85 428 -37 17 -66 21 -166 21 l-122 0 -7 -136z m266 13
c81 -54 103 -149 54 -232 -31 -54 -75 -75 -153 -75 -60 0 -61 0 -67 32 -4 17
-4 91 0 165 l7 133 63 0 c48 0 70 -5 96 -23z"/>
<path d="M2940 1858 c-54 -137 -147 -391 -154 -420 -6 -27 -5 -28 40 -28 l45
0 26 60 26 60 92 0 92 0 27 -60 c27 -60 27 -60 70 -60 23 0 45 3 49 6 8 8 -9
59 -83 249 -34 88 -71 186 -82 218 l-21 57 -47 0 -47 0 -33 -82z m107 -155
l31 -78 -60 -3 c-33 -2 -62 -1 -65 1 -2 3 7 33 21 68 15 34 26 68 26 76 0 33
19 7 47 -64z"/>
<path d="M3275 1901 c-4 -22 -4 -45 0 -51 3 -5 34 -10 69 -10 l63 0 6 -97 c4
-54 7 -151 7 -215 l0 -118 45 0 45 0 0 215 0 215 66 0 65 0 -3 48 -3 47 -177
3 -176 2 -7 -39z"/>
<path d="M4542 1919 c-85 -42 -112 -129 -63 -201 31 -44 45 -53 144 -88 44
-16 84 -33 90 -39 15 -15 -1 -59 -29 -78 -24 -16 -30 -15 -101 2 -81 20 -99
17 -120 -26 -16 -30 -5 -41 60 -63 104 -35 189 -14 267 64 33 33 40 47 40 78
0 20 -8 47 -18 58 -29 37 -103 84 -155 99 -95 28 -120 61 -76 101 17 15 34 19
82 17 33 -2 65 -7 71 -13 5 -5 13 -10 18 -10 8 0 42 60 36 65 -2 1 -21 14 -42
29 -51 34 -140 36 -204 5z"/>
<path d="M4938 1844 c-1 -55 -2 -172 -2 -261 l-1 -163 55 -2 c30 -1 104 -2
163 -2 102 -1 108 0 113 20 3 12 4 33 2 45 -3 24 -5 24 -125 27 -121 3 -123 3
-129 27 -3 14 -3 41 0 60 l7 35 100 0 c97 0 100 1 111 26 22 48 4 58 -113 64
l-104 5 0 60 0 60 128 -4 127 -3 0 46 0 46 -149 0 c-82 0 -156 3 -165 6 -14 5
-16 -7 -18 -92z"/>
<path d="M5380 1674 l0 -265 48 3 47 3 5 95 5 95 44 -3 c24 -1 51 -7 60 -11 9
-5 38 -45 64 -90 l48 -81 50 0 c57 0 58 -11 -10 105 -23 38 -41 75 -41 81 0 6
18 30 40 52 39 38 40 42 40 105 0 55 -4 70 -25 95 -45 54 -95 69 -242 76
l-133 6 0 -266z m278 146 c31 -29 38 -65 18 -95 -18 -28 -57 -38 -136 -33
l-55 3 -3 73 -3 72 79 0 c65 0 82 -3 100 -20z"/>
<path d="M6452 1679 l3 -262 48 -1 47 -1 0 126 c0 69 3 187 7 262 l6 137 -57
0 -56 0 2 -261z"/>
<path d="M7219 1936 c-2 -2 -3 -121 -1 -263 l3 -258 174 3 175 3 0 44 0 45
-125 0 -125 0 0 60 0 59 98 3 97 3 3 43 3 42 -100 0 -101 0 0 60 0 60 121 0
120 0 -3 48 -3 47 -166 3 c-91 1 -167 0 -170 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
